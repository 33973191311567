<script>
    import {Link} from 'svelte-navigator';

    export let to = '';

    const klass = 'mfuko-logo-navbar text-2xl px-3 rounded-md hover:text-orange-400';

    // eslint-disable-next-line no-unused-vars
    const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
        return { class: klass };
    };
</script>

<Link {to} {getProps}>
  <slot />
</Link>
