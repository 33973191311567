<script>
  import NavLink from './NavLink.svelte';
  import MailIcon from '../icons/MailIcon.svelte';
  import PhoneIcon from '../icons/PhoneIcon.svelte';
  import FacebookIcon from '../icons/FacebookIcon.svelte';
  import InstagramIcon from '../icons/InstagramIcon.svelte';
  import {showMobileMenu} from '../store.js';

  const closeMobileMenu = () => $showMobileMenu && showMobileMenu.set(false);
</script>

<footer class="relative w-full mt-auto flex justify-between flex-wrap bg-gray-700 text-gray-300">
  <div id="contact" class="p-5">
    <h1 class="text-xl font-medium">Kontakt</h1>
    <p class="text-sm"><PhoneIcon /> +43 650 9244 605</p>
    <p class="text-sm"><MailIcon /> mfuko@gmx.at</p>
    <p class="text-sm">Neulerchenfelderstrasse 23/22</p>
    <p class="text-sm">1160 Wien</p>
  </div>
  <div class="pt-6 xpb-5 pl-3 pr-5">
    <div>
      <button type="button" class="block" on:click={closeMobileMenu}>
        <NavLink to="retoure" footer={ true }>Retoure und Widerrufsrecht</NavLink>
      </button>
      <button type="button" class="block" on:click={closeMobileMenu}>
        <NavLink to="agb" footer={ true }>AGB</NavLink>
      </button>
      <button type="button" class="block" on:click={closeMobileMenu}>
        <NavLink to="gdpr" footer={ true }>Datenschutz</NavLink>
      </button>
      <button type="button" class="block" on:click={closeMobileMenu}>
        <NavLink to="impressum" footer={ true }>Impressum</NavLink>
      </button>
    </div>
    <div class="flex pl-1">
      <button class="p-2 rounded-md text-gray-300 hover:bg-gray-700 hover:text-white">
        <a href="mailto:mfuko@gmx.at"><MailIcon /></a>
      </button>
      <button class="p-2 rounded-md text-gray-300 hover:bg-gray-700 hover:text-white">
        <a href="https://www.facebook.com/people/Mfuko-Wien/100060573221158/"><FacebookIcon /></a>
      </button>
      <button class="p-2 rounded-md text-gray-300 hover:bg-gray-700 hover:text-white">
        <a href="https://www.instagram.com/mfuko_wien/"><InstagramIcon /></a>
      </button>
    </div>
  </div>
</footer>
