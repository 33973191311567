<script>
    export let product;
    export let locale = 'de';

    function localized_price(price) {
        return Number(price).toLocaleString(locale, {
            style: 'currency',
            currency: 'EUR'
        });
    }

    const shipping_cost = 10;
    const total_cost = Number(product.price) + 10;

    const address = 'mfuko@gmx.at';

    let subject = 'Bestellung '
    if (product.order_title) { subject += product.order_title + ' '; }
    subject += product.code;

    let title = '';
    if (product.order_title) { title += product.order_title; }
    if (product.order_title && product.title) { title += ' '; }
    if (product.title) { title += product.title; }
    let label = product.code;
    if (title) { label += ': ' + title}

    let body = `Liebe mfukos,

hiermit bestelle ich ${label} zum Preis von ${localized_price(product.price)} zuzüglich ${localized_price(shipping_cost)} Versandkostenpauschale.

Den Gesamtbetrag von ${localized_price(total_cost)} überweise ich nach Erhalt der Bestellbestätigung auf folgendes Konto:

Bank Austria
Birgit Mühlegger
IBAN: AT24 1200 0100 3240 2348
BIC: BKAUATWW

Die Rechnungsadresse lautet:
Name:
Strasse:
PLZ:
Stadt:
Kontakt für Rückfragen:

Lieferadresse (wenn abweichend):
Name:
Strasse:
PLZ:
Stadt:

Mit meiner Bestellung erkläre ich mich den Allgemeinen Geschäftsbedingungen, Widerrufsbestimmungen sowie den Datenschutzbestimmungen auf mfuko.at (https://mfuko.at) einverstanden.

Mit freundlichen Grüßen,
`;

    subject = encodeURIComponent(subject);
    body = encodeURIComponent(body);

    let href = `mailto:${address}?subject=${subject}&body=${body}`;
</script>

<a href="{href}" class="text-orange-600 hover:text-orange-700">Bestellmail senden</a>
