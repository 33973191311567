<script>
  import {Link, useLocation} from "svelte-navigator";

  import {getProduct} from '../api/Product';
  import OrderLink from '../components/OrderLink.svelte';
  import Currency from '../components/Currency.svelte';
  import ArrowCircleLeftIcon from '../icons/ArrowCircleLeftIcon.svelte';

  const location = useLocation();

  let product = null;
  let image;
  let image_size_class;

  export let productId;

  async function load(prodId) {
      product = await getProduct(prodId);
      // console.warn('LOADED product', product);
  }

  /* Get product from history state or load from server  */
  async function loadProduct() {
      if (!product) {
          product = $location.state;
          if (!product) {
              load(productId)
          // } else {
          //     console.warn('product FROM state', product);
          }
      }
  }

  $: {
      loadProduct();
      if (product) {
        image = product.images.shift();
        // console.warn(product);
        if (product.image_format == 'landscape') {
            image_size_class = 'h-32';
        } else {
            image_size_class = 'w-32';
        }
    }
  }
</script>

<div class="max-w-prose p-8">
  <h1>Bestellung</h1>
  <p>Sie können einfach per Mail bestellen.</p>
  {#if product}
    <div class="flex flex-wrap mt-2">
      <img class="{'object-center object-cover rounded-lg mr-4 mb-2 ' + image_size_class}"
           src={ image.src } alt={ image.title }>
      <div class="flex flex-wrap w-96 mb-2">
        <div>
          <div>
            <span class="font-bold">{ product.order_title }</span> { product.title }
          </div>
          <div>
            <span class="font-bold">Bestellnummer:</span> { product.code }
          </div>
          <div>
            <span class="font-bold">Preis:</span> <Currency amount={Number(product.price)} /> zzgl. <Currency amount={10} /> Versandkosten
          </div>
        </div>
        <div class="self-end w-full flex items-end justify-between">
          <div class="font-bold leading-none"><OrderLink product="{product}" /></div>
          <Link to=".." title="Zurück zum Produkt"><ArrowCircleLeftIcon/></Link>
        </div>
      </div>
    </div>

    <h3 class="mt-4">Zahlungsart</h3>
    <p class="mb-2">Derzeit sind Bestellungen nur gegen direkte Banküberweisung möglich. Die Bankverbindung lautet:<br>
      Bank Austria<br>
      Birgit Mühlegger<br>
      <span class="font-bold">IBAN:</span> AT24 1200 0100 3240 2348<br>
      <span class="font-bold">BIC:</span> BKAUATWW</p>
    <p class="font-bold">Bitte nutzen Sie als Verwendungszweck die gewünschte Produktnummer.</p>
    <h3 class="mt-4">Versand</h3>
    <p class="mb-2">Wir versenden CO2-neutral und plastikfrei aus Wien mit der Österreichischen Post nach <span class="italic">Österreich</span> und <span class="italic">Deutschland</span>. Die Versandkosten-Pauschale beträgt <Currency amount={10} />.</p>
    <p>Auf Anfrage senden wir gerne auch in andere Länder. Schreib uns einfach eine Nachricht an <a href="mailto:mfuko@gmx.at" class="text-orange-600 hover:text-orange-700">mfuko@gmx.at</a>.</p>
    <h3 class="mt-4">Lieferzeit</h3>
    <p class="mb-2">Wir verschicken unsere Ware sobald der Rechnungsbetrag bei uns eingelangt ist. Das kann 1–3 Tage dauern. Dadurch ergeben sich folgende Lieferzeiten ab Bestellungseingang:<br>
      <span class="italic">Österreich</span> ca. 3–5 Werktage<br>
      <span class="italic">Deutschland</span> ca. 5–8 Werktage </p>
    <p>Bei Versand der Ware erhältst Du ein Mail mit den zur Nachverfolgung der Sendung notwendigen Daten.</p>
  {/if}
</div>
