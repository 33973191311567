import axios from 'axios';

export const getCategoryList = async () => {
    const url = '/api/categories/';
    try {
         const response = await axios.get(url);
         return response.data;
    } catch (error) {
        console.error(error);
    }
};
