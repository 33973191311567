<script>
  import {onMount} from 'svelte';
  import {Router} from 'svelte-navigator';

  import NavLink from './NavLink.svelte';
  import NavLogoLink from './NavLogoLink.svelte';
  import MenuIcon from '../icons/MenuIcon.svelte';
  import XIcon from '../icons/XIcon.svelte';
  import {showMobileMenu} from '../store.js';

  export let categories = [];

  const toggleMobileMenu = () => showMobileMenu.set(!$showMobileMenu);

  const closeMobileMenu = () => $showMobileMenu && toggleMobileMenu();

  // Reset mobile state on ESC and window resize
  const escapeKeyListener = event => event.key === 'Escape' && closeMobileMenu();

  const mediaQueryHandler = event => !event.matches && closeMobileMenu();

  onMount(() => {
      // 640px corresponds to Tailwind CSS 'sm' breakpoint
      const mediaListener = window.matchMedia('(max-width: 640px)');
      mediaListener.addListener(mediaQueryHandler);
  });
</script>

<svelte:window on:keydown={escapeKeyListener}/>

<nav class="bg-gray-700">
  <Router>
  <div class="mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-13">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button type="button" class="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false" on:click={toggleMobileMenu}>
          <span class="sr-only">Open main menu</span>
          <MenuIcon hidden={$showMobileMenu}></MenuIcon>
          <XIcon hidden={!$showMobileMenu}></XIcon>
        </button>
      </div>

      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <button type="button" on:click={closeMobileMenu}>
          <NavLogoLink to="/">mfuko</NavLogoLink>
        </button>
        <div class="hidden sm:block sm:ml-3">
          <div class="flex items-center space-x-4">
            {#each categories as category}
              <NavLink to="{ category.slug }">{category.title}</NavLink>
            {/each}
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-3 sm:pr-0">
        <button type="button" class="hidden sm:block text-left"
                on:click={closeMobileMenu}>
          <NavLink to="ueber">Über mfuko</NavLink>
        </button>
        <a href="#contact"
           class="block sm:hidden mmd:block hover:bg-gray-700 hover:text-white block px-3 py-1 rounded-md text-sm text-gray-300 font-medium"
           on:click={closeMobileMenu}>Kontakt</a>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class={$showMobileMenu ? 'block' : 'hidden'} id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      {#each categories as category}
        <button type="button" class="block" on:click={toggleMobileMenu}>
          <NavLink to="{ category.slug }" mobile={ true }>{category.title}</NavLink>
        </button>
      {/each}
      <hr class="m-3">
      <button type="button" class="block" on:click={toggleMobileMenu}>
        <NavLink to="ueber">Über mfuko</NavLink>
      </button>
    </div>
  </div>
</Router>
</nav>
