<script>
  import {onMount} from 'svelte';
  import {Router, Route} from 'svelte-navigator';

  import {getCategoryList} from './api/Category';
  import NavBar from './components/NavBar.svelte';
  import Footer from './components/Footer.svelte';
  import Home from './routes/Home.svelte';
  import ProductCategory from './routes/ProductCategory.svelte';
  import Product from './routes/Product.svelte';
  import UnknownProduct from './routes/UnknownProduct.svelte';
  import Order from './routes/Order.svelte';
  import About from './routes/About.svelte';
  import Retoure from './routes/Retoure.svelte';
  import AGB from './routes/AGB.svelte';
  import GDPR from './routes/GDPR.svelte';
  import Impressum from './routes/Impressum.svelte';
  import NotFound from './routes/NotFound.svelte';

  let categories = [];

  onMount(async function () {
      categories = await getCategoryList();
  });

  export let url = '';
</script>

<Router url="{url}">
  <NavBar { categories }></NavBar>

  <main>
    <Route path="/" primary={false}>
      <Home />
    </Route>

    {#each categories as category}
      <Route path="{category.slug}/*" primary={false}>
        <Route path="/">
          <ProductCategory {category} />
        </Route>
        <Route path=":productId/*" let:params="{params}" primary={false}>
          <Route path="/">
            <Product slug={category.slug} productId="{params.productId}" />
          </Route>
          <Route path="bestellung" primary={false}>
            <Order productId="{params.productId}" />
          </Route>
        </Route>
      </Route>
    {/each}

    <Route path="unknown_product" primary={false}>
      <UnknownProduct />
    </Route>

    <Route path="ueber" primary={false}>
      <About />
    </Route>

    <Route path="retoure" primary={false}>
      <Retoure />
    </Route>

    <Route path="agb" primary={false}>
      <AGB />
    </Route>

    <Route path="gdpr" primary={false}>
      <GDPR />
    </Route>

    <Route path="impressum" primary={false}>
      <Impressum />
    </Route>

    <Route>
      <NotFound />
    </Route>
  </main>

  <Footer/>

</Router>


<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @font-face {
    font-family: mfukoFont;
    src: url(/assets/fonts/verdana.woff);
  }

  @font-face {
    font-family: mfukoFont;
    src: url(/assets/fonts/verdana_bold.woff);
    font-weight: bold;
  }

  @layer base {
    h1 {
      @apply text-2xl font-bold;
    }

    h2 {
      @apply text-xl font-bold;
    }

    h3 {
      @apply text-lg font-bold;
    }

    h4 {
      @apply font-bold;
    }

    .mfuko-logo {
      font-family: mfukoFont;
      @apply text-orange-600
    }

    .mfuko-logo-lg {
      font-family: mfukoFont;
      font-size: 48px;
      font-weight: bold;
      @apply text-orange-600;

    }
    .mfuko-logo-navbar {
      font-family: mfukoFont;
      @apply text-orange-500;
      font-weight: bold;
    }
  }
</style>
