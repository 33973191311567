<script>
  import {onMount} from 'svelte';

  import {getPromotedProducts} from '../api/Product';

  let products;
  let mainProduct;

  onMount(async function () {
      products = await getPromotedProducts();
      if (products.length) {
          mainProduct = products.shift();
      }
  });
</script>

<div class="max-w-96 md:max-w-[48rem] p-8">
  <div class="mfuko-logo-lg text-center">mfuko</div>

  <div class="mb-4 text-2xl text-center">mfuko (Swahili), IPA: /mˈfu.kɔ/ —— Tasche</div>

  <div class="grid grid-cols-1 md:grid-cols-2">
    {#if mainProduct}
      <div class="mb-4 md:mb-0 overflow-hidden aspect-w-3 aspect-h-4">
        <a href="{mainProduct.path}" title="Details anzeigen"
           class="aspect-w-3 aspect-h-4"><img
           class="object-center object-cover rounded-lg" src={ mainProduct.image_src }
           alt={ mainProduct.image_title }></a>
      </div>
    {/if}
    {#if products}
      <div class="grid grid-cols-2 gap-4 md:ml-4">
        {#each products as product}
          <div class="max-h-64 aspect-w-3 aspect-h-4 overflow-hidden">
            <a href="{product.path}" title="Details anzeigen"><img
               class="object-center object-cover h-full rounded-md" src={ product.image_src }
               alt={ product.image_title }></a>
          </div>
        {/each}
      </div>
    {/if}
  </div>

  <div class="mt-4 text-center">
    <div>Die Rucksäcke und Taschen von <span class="mfuko-logo">mfuko</span> werden aus Restmaterialien von Leder, gewachsten Stoffen und anderen Materialien angefertigt.</div>
    <div class="mt-1">Jeder Rucksack und jede Tasche ist ein Einzelstück.</div>
  </div>
</div>
