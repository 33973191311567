<script>
  import {onMount} from 'svelte';
  import {Link} from 'svelte-navigator';

  import {getProduct} from '../api/Product';
  import Currency from '../components/Currency.svelte';
  import PaddedNumber from '../components/PaddedNumber.svelte';
  // import OrderLink from '../components/OrderLink.svelte';
  import ArrowCircleLeftIcon from '../icons/ArrowCircleLeftIcon.svelte';
  import ArrowCircleRightIcon from '../icons/ArrowCircleRightIcon.svelte';

  let mainImage;
  let images;
  let loading = '';
  let product = null;

  let image_grid_max_width_class;
  let image_grid_max_height_class;
  let image_aspect_class;

  let mobile = window.matchMedia('(max-width: 640px)');

  export let slug;
  export let productId;

  async function load(prodId) {
      mainImage = null;
      images = null;

      product = await getProduct(prodId);

      productId = product.id;
      if (product.images && product.images.length) {
          mainImage = product.images[0];
          if (product.images.length > 0) {
              images = product.images.slice(1);
          }
      }

      if (product.image_format == 'portrait') {
          image_grid_max_width_class = 'md:max-w-[48rem]';
          image_grid_max_height_class = 'max-h-64';
          image_aspect_class = ' aspect-w-3 aspect-h-4';
      } else if (product.image_format == 'landscape') {
          image_grid_max_width_class = 'md:max-w-[64rem]';
          image_grid_max_height_class = 'max-h-32';
          image_aspect_class = ' aspect-w-4 aspect-h-3';
      }
  }

  onMount(function() {
      setTimeout(function() { loading = 'Produkt wird geladen …'; }, 500);
      load(productId);
  });

  function loadPrev() {
      if (product.prevId) { load(product.prevId); }
  }

  function loadNext() {
      if (product.nextId) { load(product.nextId); }
  }

  function switchImage(index) {
      const main_image = document.querySelector('#product-image-0');
      const image = document.querySelector('#product-image-' + (index + 1));

      const main_image_src = main_image.getAttribute('src');
      const image_src = image.getAttribute('src');

      main_image.setAttribute('src', image_src);
      image.setAttribute('src', main_image_src);

      if (mobile.matches) {
          window.scroll({
           top: 0,
           behavior: 'smooth'
          });
      }
  }

  // Avoid warnings in dev mode when created with:
  // <Route path=":productId" component={ Product } />
  // export let location;
  // location = null;
  // export let navigate;
  // navigate = null;
</script>

<div class="p-8">
  {#if product}
    <div class="{'grid grid-cols-1 md:grid-cols-2 ' + image_grid_max_width_class}">
      {#if mainImage}
        <div class="{'mb-4 md:mb-0 overflow-hidden ' + image_aspect_class}">
          <img id="product-image-0" class="object-center object-cover rounded-lg"
               src={ mainImage.src } alt={ mainImage.title }>
        </div>
      {/if}

      {#if images}
        <div class="grid grid-cols-2 gap-4 md:ml-4">
          {#each images as image, index}
            <div on:click={() => switchImage(index)}
                 class="{image_grid_max_height_class + ' overflow-hidden cursor-pointer' + image_aspect_class}">
              <img id="product-image-{index + 1}" class="object-center object-cover h-full rounded-md"
                   src={ image.src } alt={ image.title }>
            </div>
          {/each}
        </div>
      {/if}
    </div>

    <div class="max-w-96 md:max-w-[48rem] mt-4">
      <div class="flex flex-wrap justify-between">
        <div class="font-bold">{product.title}</div>
        <div class="whitespace-nowrap">
          Produktnummer:
          <span class="font-bold">{product.code}</span>
        </div>
      </div>
      <div>{@html product.description}</div>
      <div class="flex flex-wrap items-end justify-between">
        <div class="font-bold">
          {#if product.price}
            <div class="mb-2"><Currency amount={Number(product.price)} /></div>
          {/if}
          {#if product.status_info}
            <div class="font-bold">{product.status_info}</div>
          {/if}
          {#if product.orderable}
            <div><Link to='bestellung' state="{product}" class="text-orange-600 hover:text-orange-700">➜ jetzt bestellen</Link></div>
            <!-- div><OrderLink code="{product.code}" price="{product.price}" /> (Mail Link)</div -->
          {/if}
        </div>
        <div>
          <!-- 'replace={true}' fails with the Browser's back/forward buttons,
               but 'replace={false}' create history entries that do not reload the product data.
               See https://github.com/sveltejs/kit/issues/552,
                   https://github.com/sveltejs/kit/discussions/5007 and
                   https://github.com/sveltejs/kit/pull/3293 -->
          {#if product.prevId}<a href="/{slug}/{product.prevId}" on:click={loadPrev}><Link to="/{slug}/{product.prevId}" replace={true} title="Voriges Produkt"><ArrowCircleLeftIcon/></Link></a>{/if}

          {#if product.nextId}<a href="/{slug}/{product.nextId}" on:click={loadNext}><Link to="/{slug}/{product.nextId}" replace={true} title="Nächstes Produkt"><ArrowCircleRightIcon/></Link></a>{/if}
        </div>
      </div>
    </div>
  {:else}
    <div>{ loading }</div>
  {/if}
</div>
