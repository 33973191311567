import axios from 'axios';
import {navigate} from "svelte-navigator";

export const getCategoryProducts = async (categoryId) => {
    const url = `/api/products/?category=${categoryId}`;
    try {
         const response = await axios.get(url);
         return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getProduct = async (productId) => {
    const url = `/api/products/${productId}/`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        if (error.response.status === 404) {
            navigate('/unknown_product', { replace: true });
        } else {
            console.error(error);
            return {};
        }
    }
};

export const getPromotedProducts = async () => {
    const url = `/api/products/promoted/`;
    try {
         const response = await axios.get(url);
         return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
