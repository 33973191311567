<script>
  import {onMount} from 'svelte';
  import {Link} from 'svelte-navigator';

  import {getCategoryProducts} from '../api/Product';
  import PaddedNumber from '../components/PaddedNumber.svelte';

  onMount(async function () {
      setTimeout(function() { loading = '… werden geladen'; }, 500);
      products = await getCategoryProducts(category.id);
  });

  export let category = {};
  let loading = '';
  let products = null;
</script>

<div class="p-8">
  <h1>{ category.title }</h1>
    {#if products}
    <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
      {#each products as product}
        <div class="group relative text-sm min-h-[50px] text-gray-700">
          <Link to={product.url_path}>
            {#if product.image}
              <div class="w-full min-h-80 bg-gray-200 overflow-hidden group-hover:opacity-75">
                  <img src={product.image.src} alt={product.image.title}
                       class="w-full h-full object-center object-cover rounded-lg lg:w-full lg:h-full">
              </div>
              <div class="mt-4 font-bold">
                {#if product.title}{product.title}{:else}{product.code}{/if}
                {#if product.short_status_info}({product.short_status_info}){/if}
              </div>
            {:else}
              <div class="h-full flex items-center font-bold">
                {#if product.title}{product.title}{:else}{product.code}{/if}
                {#if product.short_status_info}({product.short_status_info}){/if}
              </div>
            {/if}
          </Link>
        </div>
      {/each}
    </div>
  {:else}
    <div class="mt-2">{ loading }</div>
  {/if}
</div>
