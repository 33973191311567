<script>
  import {showMobileMenu} from '../store.js';

  const closeMobileMenu = () => $showMobileMenu && showMobileMenu.set(false);
</script>

<div class="max-w-prose p-8">
  <h2>Retoure</h2>
  <p class="mb-2">Wenn du deine Bestellung oder einen Teil davon nach einem Widerruf an uns zurückschicken möchtest, ist das binnen 14 Tagen ohne Angabe von Gründen möglich. Beachte bitte die folgenden Punkte.</p>
  <h3>Verpackung und Zustand der Ware</h3>
  <p class="mb-2">Verpacke die Artikel gut, um Transportschäden zu vermeiden. Am besten verwendest du dazu das Paket und die Verpackungsmaterialien, die du von uns bekommen hast. Bitte achte darauf, dass die retournierten Artikel unbenutzt und im Originalzustand sind, damit wir den vollen Kaufpreis erstatten können. Artikel, die kaputt oder gebraucht bei uns zurückkommen, können nicht erstattet werden.</p>
  <h3>Frankierung der Rücksendung</h3>
  <p class="mb-2">Da wir ein kleines Unternehmen sind können wir die Kosten für Retouren leider nicht übernehmen. Bitte achte darauf, dass deine Rücksendung ausreichend frankiert ist. Bei unfrei zurückgesendeten Paketen und nicht ausreichend frankierten Paketen wird die anfallende Strafgebühr der Österreichischen Post mit der Gutschrift der Retoure verrechnet</p>
  <p class="mb-2"><span class="font-bold">Die Rücksendeadresse lautet:</span><br>
    mfuko e.U.<br>
    Neulerchenfelderstrasse 23/22<br>
    1160 Wien<br>
    Austria</p>
  <p class="mb-2">Sobald deine Retoure von uns bearbeitet wurde erhältst du von uns eine Bestätigungsmail. Die Rückerstattung der retournierten Artikel erhältst du innerhalb von 7 Werktagen nach Versand der Bestätigungsmail und zwar über die Zahlungsart, die du für deinen Einkauf verwendet hast.</p>
  <p>Ein Umtausch von Artikeln ist leider nicht möglich, eine Neubestellung aber jederzeit.</p>

  <h2 class="mt-8">Widerrufsrecht</h2>
  <p class="mb-2">Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.</p>
  <h3>Widerrufsbelehrung</h3>
  <p class="mb-2">Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.</p>
  <p class="mb-2">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br>
    mfuko e.U.<br>
    Neulerchenfelderstrasse 23/22<br>
    1160 Wien<br>
    Austria<br>
    Tel. +43 650 9244 605<br>
    E-Mail: <a href="mailto:mfuko@gmx.at" class="text-orange-600 hover:text-orange-700">mfuko@gmx.at</a><br>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.</p>
  <p class="mb-2">Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
  <h3>Folgen des Widerrufs</h3>
  <p class="mb-2">Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.</p>
  <p class="mb-2">Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
  <p class="mb-2">Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</p>
  <p class="mb-2">Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14 Tagen absenden.</p>
  <p class="mb-2">Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
  <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
</div>
