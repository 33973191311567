<script>
  import {showMobileMenu} from '../store.js';

  const closeMobileMenu = () => $showMobileMenu && showMobileMenu.set(false);
</script>

<div class="max-w-prose p-8">
  <h1 class="mb-3">Datenschutzerklärung</h1>
  <p>Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen      Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).</p>
  <p>Sobald Sie als Benutzer auf unsere Website zugreifen oder diese besuchen wird Ihre IP-Adresse erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.</p>
  <p>Diese Website verwendet keine Cookies.</p>

  <h2 class="mt-4">Server-Log Files</h2>
  <p>Diese Webseite erhebt im Zuge der Webseitennutzung automatisch Informationen im Rahmen von Log Files. Dies betrifft insbesondere die IP-Adresse oder Hostname und den verwendeten Browser.</p>
  <p>Die erhobenen Informationen werden nicht personenbezogen verarbeitet oder mit personenbezogenen Daten in Verbindung gebracht.</p>

  <h2 class="mt-4">Kontakt mit uns</h2>
  <p>Wenn Sie uns per Email kontaktieren, werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für den zur Bearbeitung Ihrer Abfrage notwendigen Zeitraum bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.</p>

  <h2 class="mt-4">Ihre Rechte als BetroffeneR</h2>
  <p>Sie als BetroffeneR haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:</p>
  <ul class="list-disc pl-8">
    <li>Auskunft</li>
    <li>Löschung der Daten</li>
    <li>Berichtigung der Daten</li>
    <li>Übertragbarkeit der Daten</li>
    <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
    <li>Einschränkung</li>
  </ul>
  <p class="mt-2">Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die Möglichkeit sich bei uns (siehe
    <a href="#contact"
       class="underline hover:font-bold"
       on:click={closeMobileMenu}>Kontakt</a>)
    oder der Datenschutzbehörde zu beschweren.</p>
</div>
