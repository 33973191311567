<script>
    export let hidden = false;

    function getClass(hidden) {
        return "h-6 w-6 " + (hidden ? "hidden" : "block");
    }
</script>

<svg class={ getClass(hidden) } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden={ hidden }>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg>
