<script>
</script>

<div class="max-w-prose p-8">
  <h1 class="mb-3">Über mfuko</h1>
  <p class="mb-2">Die Taschen und Rucksäcke von mfuko werden aus den verschiedensten Materialien wie Leder, gewachsten Stoffen und Andrucken aus dem Siebdruck u.a. hergestellt.</p>
  <div class="mb-2">
    <img src="/static/mfuko/werkstatt.jpg" alt="mfuko Werkstatt" class="max-w-full md:max-w-[375px]">
  </div>
  <p class="mb-2">Sie werden mit hoher handwerklicher Sorgfalt in einer kleinen Werkstatt in Wien genäht.
Zur Herstellung verwendet werden ausschließlich Reste und Restposten. Neu zugekauft werden – mangels Verfügbarkeit – nur die Gurte und Plastikteile.</p>
  <p class="mb-2">Scheinbar nutzlose Reste erfahren so eine Umwandlung in neue, hochwertige Produkte.
Upcycling bedeutet ein hohes Maß an Individualität und vielfältigem Design:</p>
  <p class="mb-6 font-bold">Jeder mfuko ist ein Einzelstück – Keiner gleicht dem Anderen.</p>
  <div class="mb-4 md:grid md:grid-cols-2 md:gap-4 clear-right">
    <img src="/static/mfuko/werkstatt1.jpg" alt="mfuko Material" class="mb-2 md:hidden">
    <div>
      <p class="mb-2">mfuko sind wasserfest und robust.</p>
      <p class="mb-2">Die innen gepolsterte Rücken- und Bodenfläche sowie die lederunterlegten Tragegurte bieten auch bei voller Bepackung einen angenehmen Tragekomfort.</p>
      <p class="mb-2">Jeder mfuko verfügt über ein oder mehrere geräumige Außentaschen mit Reißverschluss.</p>
      <p class="mb-2">Im Innenraum finden sich je nach Modell eine gepolsterte, verschließbare Laptoptasche und ein- oder mehrere Innentaschen und Schlüsselkarabiner.</p>
    </div>
    <img src="/static/mfuko/werkstatt1.jpg" alt="mfuko Material" class="mb-2 hidden md:block">
  </div>
  <div class="mb-2 md:grid md:grid-cols-2 md:gap-4 clear-right">
    <img src="/static/mfuko/werkstatt2.jpg" alt="mfuko Werkstatt 1" class="mb-2">
    <div>
      <p class="mb-2">Dies ermöglicht eine gute und übersichtliche Organisation des Inhalts der Tasche bzw. des Rucksacks – kein langes und umständliches Suchen des Schlüsels mehr!</p>
      <p class="mb-2">Das Fassungsvermögen aller Modelle lässt sich zusätzlich über den Rollüberschlag auf das doppelte Volumen erweitern.</p>
      <p class="mb-2">Durch die unterschiedlichen Modelle gibt es für jeden Anlass das passende Produkt – vom kurzen Gastgartenbesuch bis zur langen Wanderung.</p>
    </div>
  </div>
  <p class="mb-2">In diesen Geschäften sind mfuko Taschen und Rucksäcke erhältlich:<br>
    <span class="font-bold">Galerie Unikat</span>, 1080 Wien, Josefstädterstraße 40, Tel. 01-408 66 13<br>
    Öffnungszeiten: Mo.–Fr. 10:00–18:00, Sa. 10:00–14:00
  </p>
</div>
