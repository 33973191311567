<script>
  import MailIcon from '../icons/MailIcon.svelte';
  import PhoneIcon from '../icons/PhoneIcon.svelte';
</script>

<div class="max-w-prose p-8">
  <h1 class="mb-3">Impressum</h1>
  <h2 class="mb-1">mfuko e.U.</h2>
  <p><span class="font-bold">Inhaberin:</span> Birgit Mühlegger</p>
  <p>Eingetragenes Einzelunternehmen
  <p class="mt-4"><span class="font-bold">FN:</span> 546688 d</p>

  <p class="mt-4">Neulerchenfelderstrasse 23/22</p>
  <p>1160 Wien</p>
  <p><PhoneIcon></PhoneIcon> +43 650 9244 605</p>
  <p><MailIcon></MailIcon> mfuko@gmx.at</p>
</div>
