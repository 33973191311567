<script>
    import { Link } from 'svelte-navigator';

    export let to = '';
    export let mobile = false;
    export let footer = false;

    const baseKlass = 'hover:text-white block px-3 rounded-md font-medium';

    // eslint-disable-next-line no-unused-vars
    const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
        const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;

        // The object returned here is spread on the anchor element's attributes
        let klass = baseKlass;

        if (!footer) {
            klass += ' py-2';
        }

        if (mobile) {
            klass += ' block text-base';
        } else {
            klass += ' text-sm';
        }

        if (isActive) {
            klass += ' text-white';
        } else {
            klass += ' text-gray-300';
        }
        return { class: klass };
    };
</script>

<Link {to} {getProps}>
  <slot />
</Link>
