<script>
  import Currency from '../components/Currency.svelte';
  import {showMobileMenu} from '../store.js';

  const closeMobileMenu = () => $showMobileMenu && showMobileMenu.set(false);
</script>

<div class="max-w-prose p-8 text-sm">
  <h2 class="mt-4">Allgemeine Geschäftsbedingungen</h2>
  <p class="mb-2">Allgemeine Geschäftsbedingungen für Endkunden, gültig ab 01.07.2022.
</p>
  <p>AGB des mfuko Online Shops, im weiteren mfuko.at genannt im geschäftlichen Verkehr mit Verbraucher:innen im Sinne des Konsumentenschutzgesetzes (KSchG), im weiteren Kund:innen genannt.</p>
  <h3 class="mt-4 text-base">Allgemeines</h3>
  <p>Alle Lieferungen und Leistungen der Verkäuferin im Rahmen des Onlineshops mfuko.at erfolgen ausschließlich auf Grundlage der nachfolgenden allgemeinen Geschäfts&shy;bedingungen (AGB) in der zum Zeitpunkt der Bestellung geltenden Fassung, sofern keine anderen Regelungen mit Kund:innen vereinbart wurden.</p>
  <h3 class="mt-4 text-base">Vertragsabschluss</h3>
  <p class="mb-2">Die Präsentation der Produkte auf mfuko.at stellt kein verbindliches Angebot an den/die Kund:in dar, sondern entspricht einer unverbindlichen Preisliste. Die Vertrags-, Bestell- und Geschäftssprache ist Deutsch.</p>
  <p class="mb-2">Die Absendung einer Bestellung durch den/die Kund:in gilt lediglich als Angebot zum Abschluss eines Kaufvertrages. Der Inhalt des Angebots kann ausschließlich aus den auf der aktuellen mfuko.at Seite enthaltenen Waren sowie die beim Absenden der Bestellung auf mfuko.at angeführten Konditionen (Versandart, Zahlungsart) bestehen. Nach Eingang der Bestellung erhält der/die Kund:in eine E-Mail von mfuko.at, die den Eingang der Bestellung bei mfuko.at bestätigt. Diese Bestätigung stellt keine Annahme des Angebotes des Kunden durch mfuko.at dar, sondern dient lediglich der Benachrichtigung des/der Kund:in über den Eingang der Bestellung bei mfuko.at.</p>
  <p class="mb-2">Abhängig davon, ob das online bestellte Produkt in den Lagerbeständen von mfuko.at verfügbar ist oder nicht, erhält der/die Kund:in nach Bearbeitung der Bestellung und nach Zahlungseingang eine weitere E-Mail-Nachricht (Rechnung), mit welcher der Auftrag bestätigt wird. Mit Zustellung dieser E-Mail (Rechnung) kommt der Kaufvertrag endgültig zustande.</p>
  <p>Sollte binnen 10 Werktagen ab Bestellung keine Leistung der Vorauszahlung durch den/die Kund:in erfolgen, behält sich mfuko.at das Recht vor, die Bestellung mangels Voraus&shy;zahlungseingang zu stornieren und die Waren für andere Kund:innen freizugeben. Bei Versand der Waren wird dem/der Kund:in eine weitere E-Mail übermittelt (Versand&shy;bestätigung). Diese E-Mail enthält für eventuelle Sendungsverfolgung notwendige Paketdaten.</p>
  <h3 class="mt-4 text-base">Preise und Zahlungsbedingungen</h3>
  <p class="mb-2">Alle Preise verstehen sich inklusive ausreichender und fachgerechter Verpackung und zuzüglich einer Versandkostenpauschale. Die Verrechnung erfolgt in € (EURO).</p>
  <p class="mb-2">Der Versand erfolgt erst nach vollständigem Geldeingang bei mfuko.at.</p>
  <p>Kein Steuerausweis, da Kleinunternehmen gemäß §6(1)27 UStG</p>
  <h3 class="mt-4 text-base">Lieferung und Transportschäden</h3>
  <p class="mb-2">Erfüllungsstandort für alle Warenbestellungen ist der Firmenstandort von mfuko.at (Wien). </p>
  <p class="mb-2">Die Lieferung erfolgt normalerweise binnen 72 Stunden, jedenfalls aber innerhalb von 7 Werktagen, sofern nicht anders in den Produktdetails angegeben, ab Einlangen des Kaufpreises.</p>
  <p class="mb-2">Soweit eine Lieferung an den Besteller nicht möglich ist, weil zum Beispiel der/die Besteller:in nicht unter der von ihm angegebenen Lieferadresse angetroffen wird, obwohl der Lieferzeitpunkt mit angemessener Frist angekündigt wurde, trägt der/die Besteller:in die Kosten für den erfolglosen Anlieferungsversuch.</p>
  <p class="mb-2">Sollte eine Lieferung vereinbart sein und von dem/der Kund:in ungerechtfertigt die Annahme der Sendung verweigert werden, wird dieser/diese mit den gesamten Versandspesen, gegebenenfalls Nachnahmegebühren, Rücksende&shy;spesen und einer Bearbeitungsgebühr in der Höhe von <Currency amount={18} /> inkl. 20% USt. belastet.</p>
  <p>Zwecks Absicherung etwaiger Kundenansprüche aus Transportschäden gegenüber dem jeweils mit dem Versand der Bestellung beauftragten Transportunternehmen, müssen äußerlich erkennbare Transportschäden umgehend bei Anlieferung der Bestellung beim Kunden dem Paketboten angezeigt werden. Äußerlich nicht erkennbare Transportschäden müssen innerhalb von 6 Werktagen ab Zustellung schriftlich und inklusive Fotomaterial bei mfuko.at reklamiert werden, da andernfalls der Anspruch auf Geltendmachung der Ansprüche aus dem Transportschaden verfällt.</p>
  <h3 class="mt-4 text-base">Gewährleistung und Haftung</h3>
  <p>Die gesetzliche Gewährleistungsfrist beträgt 24 Monate. Die Frist beginnt mit Übergabe der Ware. Der Ersatz für Mangelfolgeschäden, sonstige unmittelbare Schäden und Verluste oder entgangenen Gewinn aufgrund mangelhafter, unterbliebener oder verspäteter Lieferung ist ausgeschlossen, sofern die Schäden leicht fahrlässig verursacht wurden und es sich dabei um keine Personenschäden handelt. Die Haftung für Personenschäden sowie eventuelle Ersatzansprüche gegen den Hersteller nach dem Produkthaftungsgesetz werden dadurch nicht beschränkt.</p>
  <h3 class="mt-4 text-base">Widerufsrecht</h3>
  <p class="mb-2">Wenn der Kaufvertrag unter ausschließlicher Zuhilfenahme fernkommunikationstauglicher Mittel, wie in § 5a Abs 2 KSchG (Konsumenten&shy;schutzgesetz) beschrieben, zustande kommt, kann der/die Verbraucher:in seine/ihre Bestellung innerhalb von 14 Tagen ab Erhalt der Ware ohne Angabe von Gründen in Textform (E-Mail, Brief) oder durch Rücksendung der Ware widerrufen. Eine KschG-konforme Widerrufsbelehrung wird dem/der Verbraucher:in auf der Seite mfuko.at zur Kenntnis gebracht. Die Frist beginnt mit dem Tag des Eingangs der Ware bei dem/der Verbraucher:in. Zur Wahrung der Frist genügt die rechtzeitige Absendung des schriftlichen Widerrufs an:<br>
    mfuko e.U.<br>
    Neulerchenfelderstrasse 23/22<br>
    1160 Wien<br>
    Austria<br>
    E-Mail: mfuko@gmx.at</p>
  <p>Im Fall des wirksamen Widerrufs sind beiderseits empfangene Leistungen Zug um Zug zurückzustellen und gegebenenfalls gezogene Nutzungen herauszugeben. Kann der Verbraucher die empfangene Ware nicht, nur teilweise oder in verschlechtertem Zustand zurückgeben, muss er Wertersatz leisten. Dies gilt nicht, wenn die Verschlechterung der Ware ausschließlich auf deren Prüfung – wie sie Ihnen etwa im Ladengeschäft möglich gewesen wäre – zurückzuführen ist. Der/die Verbraucher:in kann die Wertersatzpflicht vermeiden, indem er die Ware nicht wie sein Eigentum in Betrieb nimmt und alles unterlässt, was den Wert beeinträchtigen kann. Es gilt nach § 5g Abs 2 KSchG als vereinbart, dass der/die Verbraucher:in die Kosten des Rücktransports, unabhängig vom Wert der Sache, zu tragen hat, wenn die gelieferte Ware der bestellten entspricht.</p>
  <p></p>
  <h3 class="mt-4 text-base">Anwendbares Recht, Gerichtsstand</h3>
  <p class="mb-2">mfuko.at anerkennt den Internet Ombudsmann (Internet Ombudsmann, 1050 Wien, Margaretenstraße 70/2/10; www.ombudsmann.at) als außergerichtliche Streitschlichtung an. Hat der Verbraucher im Inland seinen Wohnsitz oder seinen gewöhnlichen Aufenthalt oder ist er im Inland beschäftigt, so kann für eine Klage gegen ihn nach den §§ 88, 89, 93 Abs. 2 und 104 Abs. 1 JN nur die Zuständigkeit des Gerichtes begründet werden, in dessen Sprengel der Wohnsitz, der gewöhnliche Aufenthalt oder der Ort der Beschäftigung des Verbrauchers liegt; dies gilt nicht für Rechtsstreitigkeiten, die bereits entstanden sind.</p>
  <p>Für eventuelle gerichtliche Auseinandersetzungen aus dem Vertrag sind auch die zwingenden verbraucherrechtlichen Bestimmungen am Wohnsitz des Verbrauchers auf das Vertragsverhältnis anwendbar. Es gilt ausschließlich österreichisches Recht unter Ausschluss des UN-Kaufrechts.</p>
  <h3 class="mt-4 text-base">Datenschutz</h3>
  <p class="mb-2">Kundendaten werden seitens von mfuko.at elektronisch erfasst und verarbeitet. Im Zuge der Geschäftsbeziehung bekannt gegebene persönliche Daten werden vertraulich behandelt. mfuko.at nützt die Daten für die Abwicklung von Bestellungen, die Lieferung von Waren und das Erbringen von Dienstleistungen sowie zur Kommunikation mit dem Inhaber der erfassten Daten über Bestellungen, Produkte und erbrachte oder zu erbringende Dienstleistungen. Die Daten werden an Dritte nur im notwendigen Ausmaß weitergegeben, wenn es für die Erbringung technischer, logistischer oder anderer Dienstleistungen im eindeutigen Kundeninteresse notwendig ist.</p>
  <p>Personenbezogenen Daten werden auf Antrag der Kunden in der Kundendatei der mfuko.at gelöscht. Weiterführende Informationen zum Datenschutz bei mfuko.at sowie Beschwerden und Datenauskünfte können an die E-Mail mfuko@gmx.at gerichtet werden.</p>
  <h3 class="mt-4 text-base">Verschiedenes</h3>
  <p class="mb-2">Sollten einzelne Bestimmungen der gegenständlichen Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein, so berührt dies die Wirksamkeit des Vertrags im Übrigen nicht. Die Parteien werden sich für diesen Fall um eine Regelung bemühen, die der unwirksamen Bestimmung wirtschaftlich und ihrer Intention nach am nächsten kommt.</p>
  <p>mfuko.at ist berechtigt, Verpflichtungen teilweise oder ganz durch Subunternehmer erbringen zu lassen.</p>
</div>
