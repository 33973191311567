<script>
    export let amount;
    export let locale = 'de';

    $: formattedNumber = amount.toLocaleString(locale, {
        style: 'currency',
        currency: 'EUR'
    });
</script>

<span class="tabular-nums">{formattedNumber}</span>
